
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// @ts-nocheck
import { Component, Vue, Watch } from 'vue-property-decorator'
import SellerHeader from '@/components/Seller/SellerHeader.vue'
import {
  getTicketsBySeller,
  getCategoriesBySeller,
  getProductsMinimalByEvent,
  getTicketsExcel,
  getSellersOfEvent,
  reassignSeller,
} from '@/services/seller.service'
import { isoDateToDay, isoDateToMonth } from '@/helpers/date'
import { Ticket } from '@/interfaces/ticket.interface'
import { ErrorMessages } from '@/helpers/constants'
import { State } from 'vuex-class'
import _ from 'lodash'
import { getNameRoute } from '@/utils/router/router.utils'

@Component({
  components: {
    SellerHeader,
  },
})
export default class Tickets extends Vue {
  //@State(({ sellerState }) => sellerState.eventSelected) eventId!: number;
  eventId!: number = localStorage.getItem('selectedEvent')
  public tickets: Ticket[] = []
  public ticketsFiltered: Ticket[] = []
  public productsAutocomplete: any = []
  public categories: any = []
  public filter = ''
  public page = 1
  public totalPages = 1
  public filterByID = ''
  public filterByCategory = ''
  public search = ''
  public full_quota = ''
  public skeletonCount = [0, 1, 2, 3, 4]
  public loading = false
  public title = ''
  public selectedTicket: Ticket = {}
  public reassignLoading: boolean = false
  public reassignedSeller = ''
  public full_quota_options = [
    { value: 'complete', text: 'Cupo completo' },
    { value: 'incomplete', text: 'Cupo incompleto' },
    { value: false, text: 'Todos' },
  ]
  public sellers_options: any = []
  public sellers: any = []
  public seller_selected = ''
  public timeout = null

  // Params
  public allTickets = this.$route.params.allTickets ? true : false
  public productId = this.$route.query.productId

  async copyToClipBoard(text: string) {
    navigator.clipboard.writeText(text).then(function() {
      Vue.$toast.success('Número de operación copiado con éxito')
    })
  }

  setTicketsQuotaColor(constrain: boolean) {
    if (constrain) {
      return 'text-color-red'
    }
    return 'text-color-blue'
  }
  async getTickets(
    page?: number,
    filters: any = {
      reference: '',
      category: false,
      product: false,
      full_quota: false,
      seller: false,
    }
  ) {
    //const loading = this.$loading.show();
    try {
      this.loading = true

      this.allTickets = getNameRoute(this.$route) === 'all-links'

      const { data, meta } = await getTicketsBySeller(
        this.eventId,
        page,
        50,
        filters,
        this.allTickets
      )
      this.tickets = data

      this.totalPages = Math.ceil(meta.total / 50)
      this.ticketsFiltered = _.orderBy(this.tickets, 'id', 'desc')
      this.loading = false

      //loading.hide();
    } catch (error) {
      Vue.$toast.error(ErrorMessages.COMMON)
      console.error(error)
      //loading.hide();
    }
  }

  async getCategories() {
    try {
      const data = await getCategoriesBySeller(this.eventId)
      this.categories = data.data.map((category: any) => {
        return {
          value: category.id,
          text: category.name,
        }
      })

      localStorage.setItem(
        'ticket-qr-categories-filter',
        JSON.stringify(this.categories)
      )
    } catch (error) {
      Vue.$toast.error(ErrorMessages.COMMON)
    }
  }

  async getProducts() {
    try {
      const data = await getProductsMinimalByEvent(this.eventId)
      this.productsAutocomplete = data.data
    } catch (error) {
      Vue.$toast.error(ErrorMessages.COMMON)
    }
  }

  dateToMonth(isoDate: string, type: string) {
    if (type === 'day') {
      return isoDateToDay(isoDate)
    }

    return isoDateToMonth(isoDate, true)
  }
  getLinkDetail(uuid: string) {
    this.$router.push({
      name:
        getNameRoute(this.$route) === 'all-links' ? 'Link-how-admin' : 'Link',
      params: { linkId: uuid },
    })
  }

  searchLinkFullQuota() {
    this.page = 1

    this.getTickets(this.page, {
      reference: this.search,
      category: this.filterByCategory,
      product: this.filterByID,
      full_quota: this.full_quota,
      seller: this.seller_selected,
    })
  }

  searchLinkBySeller() {
    this.page = 1

    this.getTickets(this.page, {
      reference: this.search,
      category: this.filterByCategory,
      product: this.filterByID,
      full_quota: this.full_quota,
      seller: this.seller_selected,
    })
  }

  searchLinkProduct() {
    this.page = 1

    this.getTickets(this.page, {
      reference: this.search,
      category: this.filterByCategory,
      product: this.filterByID,
      full_quota: this.full_quota,
      seller: this.seller_selected,
    })
  }

  searchLinkCategory() {
    this.page = 1

    this.getTickets(this.page, {
      reference: this.search,
      category: this.filterByCategory,
      product: this.filterByID,
      full_quota: this.full_quota,
      seller: this.seller_selected,
    })
  }

  searchLink() {
    this.loading = true
    this.search = this.filter

    if (this.search.length == 0 || this.search.length > 3) {
      this.page = 1

      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.getTickets(this.page, {
          reference: this.search,
          category: this.filterByCategory,
          product: this.filterByID,
          full_quota: this.full_quota,
          seller: this.seller_selected,
        })
      }, 800);
    }
    this.loading = false
  }

  async getTicketsInExcel() {
    const loader = Vue.$loading.show()

    const response = await getTicketsExcel(this.eventId)

    loader.hide()

    return response
  }

  async getSellersOfEvent() {
    try {
      this.sellers = await getSellersOfEvent(this.eventId)

      this.sellers_options = [{ id: '', fullname: 'Todos' }, ...this.sellers]
    } catch (error) {
      Vue.$toast.error(ErrorMessages.COMMON)
    }
  }

  async goToRefundTicket(ticket) {
    this.$router.push({
      name: 'Reembolsar Ticket',
      params: {
        ticket_uuid: ticket.uuid,
        event_id: this.eventId,
      },
    })
  }

  openSellerModal(ticket: Ticket) {
    this.selectedTicket = ticket
    this.$refs['seller-modal'].show()
  }

  async onReassignSeller(sellerId: number) {
    try {
      this.reassignLoading = true
      const response = await reassignSeller(
        this.selectedTicket.ticket_uuid,
        sellerId
      )
      Vue.$toast.success(response.data.message)
      this.$refs['seller-modal'].hide()
      this.getTickets(this.page, {
        reference: this.search,
        category: this.filterByCategory,
        product: this.filterByID,
        full_quota: this.full_quota,
        seller: this.seller_selected,
      })
    } catch (e) {
      Vue.$toast.error(e.response.data.message)
    } finally {
      this.reassignLoading = false
    }
  }

  /**
   * This method is called after the component is mounted.
   * It retrieves data from local storage, sets the state of the component based on the route name,
   * and makes several API calls to fetch necessary data.
   */
  mounted() {
    // Check if 'ticket-qr-categories-filter' exists in local storage
    const data = localStorage.getItem('ticket-qr-categories-filter')

    // If data exists, parse it to JSON and assign it to categories
    if (data) {
      this.categories = JSON.parse(data)
    }

    // Fetch categories and products
    this.getCategories()
    this.getProducts()

    // If allTickets is true, fetch sellers of the event
    if (this.allTickets) {
      this.getSellersOfEvent()
    }

    // If productId exists, set filterByID to productId
    if (this.productId) {
      this.filterByID = this.productId
    }

    // Set the title based on the current route
    this.title =
      getNameRoute(this.$route) === 'all-links'
        ? 'Todos los Tickets'
        : 'Mis Tickets generados'
  }

  @Watch('page', { immediate: true, deep: true })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onPageChange(nextPage: number, _currentPage: unknown) {
    if (this.productId) this.filterByID = this.productId
    this.getTickets(nextPage, {
      reference: this.search,
      category: this.filterByCategory,
      product: this.filterByID,
      full_quota: this.full_quota,
      seller: this.seller_selected,
    })
  }
}
